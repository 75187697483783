import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Search, MapPin, Image as ImageIcon } from 'lucide-react';
import { fetchExhibits } from '../services/Exhibit';

function ExhibitTable() {
    const [exhibits, setExhibits] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchExhibits();
                setExhibits(data);
                const uniqueRooms = Array.from(new Set(data.map(exhibit => exhibit.room)))
                    .filter(room => room)
                    .map(room => ({ id: room, name: room }));
                setRooms(uniqueRooms);
            } catch (error) {
                console.error('Error fetching exhibits:', error);
            }
        };

        fetchData();
    }, [i18n.language]);

    const filteredExhibits = exhibits
        .filter(exhibit => selectedRoom ? exhibit.room === selectedRoom : true)
        .filter(exhibit => 
            searchQuery ? 
                exhibit.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                exhibit.description?.toLowerCase().includes(searchQuery.toLowerCase())
            : true
        );

    return (
        <div className="min-h-screen bg-teal bg-opacity-10 p-4 sm:p-6 lg:p-8">
            <div className="max-w-7xl mx-auto">
                <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
                    <h1 className="text-3xl font-bold text-dark-blue mb-6">{t('exhibits')}</h1>
                    
                    <div className="flex flex-col sm:flex-row gap-4 mb-6">
                        <div className="relative flex-1">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-dark-blue h-5 w-5" />
                            <input
                                type="text"
                                placeholder={t('searchExhibits')}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 border-2 border-dark-blue rounded-lg focus:ring-2 focus:ring-teal focus:border-transparent"
                            />
                        </div>
                        <div className="relative">
                            <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-dark-blue h-5 w-5" />
                            <select
                                onChange={e => setSelectedRoom(e.target.value)}
                                value={selectedRoom}
                                className="pl-10 pr-8 py-2 border-2 border-light-yellow rounded-lg focus:ring-2 focus:ring-teal focus:border-transparent appearance-none bg-white"
                            >
                                <option value="">{t('roomSelection')}</option>
                                {rooms.map(room => (
                                    <option key={room.id} value={room.id}>{room.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Desktop View */}
                    <div className="hidden sm:block overflow-hidden rounded-lg border-2 border-dark-blue">
                        <table className="min-w-full divide-y-2 divide-dark-blue">
                            <thead className="bg-dark-blue bg-opacity-30">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-dark-blue uppercase tracking-wider">{t('image')}</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-dark-blue uppercase tracking-wider">{t('name')}</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-dark-blue uppercase tracking-wider">{t('room')}</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-dark-blue uppercase tracking-wider">{t('description')}</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y-2 divide-dark-blue">
                                {filteredExhibits.length > 0 ? (
                                    filteredExhibits.map(exhibit => (
                                        <tr
                                            key={exhibit.id}
                                            onClick={() => navigate(`/exhibits/${exhibit.uuid}`)}
                                            className="cursor-pointer hover:bg-dark-blue hover:bg-opacity-20 transition-colors duration-150"
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {exhibit.image && exhibit.image.length > 0 ? (
                                                    <img
                                                        src={exhibit.image[0]}
                                                        alt={exhibit.name}
                                                        className="h-12 w-12 object-cover rounded-lg shadow-sm"
                                                    />
                                                ) : (
                                                    <div className="h-12 w-12 rounded-lg bg-light-yellow bg-opacity-30 flex items-center justify-center">
                                                        <ImageIcon className="h-6 w-6 text-dark-blue" />
                                                    </div>
                                                )}
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className="text-sm font-medium text-dark-blue">{exhibit.name}</div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <span className="px-2 py-1 text-xs font-medium rounded-full bg-light-yellow text-dark-blue">
                                                    {exhibit.room}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className="text-sm text-dark-blue opacity-80 truncate max-w-xs">
                                                    {exhibit.description || t('noDescription')}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="px-6 py-4 text-center text-dark-blue">{t('noExhibits')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Mobile View */}
                    <div className="sm:hidden space-y-4">
                        {filteredExhibits.length > 0 ? (
                            filteredExhibits.map(exhibit => (
                                <div
                                    key={exhibit.id}
                                    onClick={() => navigate(`/exhibits/${exhibit.uuid}`)}
                                    className="bg-white rounded-lg border-2 border-light-yellow hover:border-teal transition-colors duration-150 cursor-pointer overflow-hidden"
                                >
                                    <div className="p-4">
                                        <div className="flex items-center space-x-4">
                                            {exhibit.image && exhibit.image.length > 0 ? (
                                                <img
                                                    src={exhibit.image[0]}
                                                    alt={exhibit.name}
                                                    className="h-16 w-16 object-cover rounded-lg shadow-sm"
                                                />
                                            ) : (
                                                <div className="h-16 w-16 rounded-lg bg-light-yellow bg-opacity-30 flex items-center justify-center">
                                                    <ImageIcon className="h-8 w-8 text-dark-blue" />
                                                </div>
                                            )}
                                            <div className="flex-1 min-w-0">
                                                <h3 className="text-lg font-semibold text-dark-blue mb-1">{exhibit.name}</h3>
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-light-yellow text-dark-blue">
                                                    {exhibit.room}
                                                </span>
                                            </div>
                                        </div>
                                        <p className="mt-3 text-sm text-dark-blue opacity-80 line-clamp-2">
                                            {exhibit.description || t('noDescription')}
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center py-8 text-dark-blue">{t('noExhibits')}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExhibitTable;