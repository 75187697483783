import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Homepage() {
    const { t } = useTranslation(); // Use `t` for translations
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-teal bg-opacity-10 py-8 px-4 sm:px-6 lg:px-8">
            <div className="p-8 max-w-4xl mx-auto bg-white shadow-lg rounded-lg relative overflow-hidden ">
                {/* Logo Section */}
                <div className="flex justify-center mb-2">
                    <img
                        src="/images/logo.svg" // Update with your logo path
                        alt="Project Logo"
                        className="w-auto h-48 object-contain"
                    />
                </div>

                <div className="flex justify-center mb-8 gap-4">
                    <button
                        onClick={() => navigate('/exhibits')}
                        className="bg-light-yellow text-dark-blue py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                    >
                        {t('exhibitList')}
                    </button>
                    <button
                        onClick={() => navigate('/scan')}
                        className="bg-dark-blue text-white py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                    >
                        {t('scanQRCode')}
                    </button>
                </div>

                <h1 className="text-4xl text-center font-bold mb-6 text-dark-blue">{t('homepage.title')}</h1>
                <p className="text-lg mb-4">{t('homepage.introduction')}</p>
                <p className="text-lg mb-4">{t('homepage.projectObjectives')}</p>
                <ul className="list-disc list-inside text-lg mb-4 pl-6">
                    <li>{t('homepage.objective1')}</li>
                    <li>{t('homepage.objective2')}</li>
                    <li>{t('homepage.objective3')}</li>
                    <li>{t('homepage.objective4')}</li>
                </ul>
                <p className="text-lg mb-4">{t('homepage.activities')}</p>
                <p className="text-lg mb-6">{t('homepage.expectedImpact')}</p>
                <p className="text-lg mb-6">{t('homepage.invitation')}</p>
            </div>
    </div>
    );
}

export default Homepage;
