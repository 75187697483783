import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Header() {
    const { i18n, t } = useTranslation(); // Destructure `t` for translations
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng); // Store the selected language in localStorage
        setDropdownOpen(false); // Close the dropdown after selecting a language
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <header className="bg-teal text-white p-4 flex flex-col sm:flex-row justify-between items-center shadow-md">
            <div className="flex items-center space-x-4 mb-4 sm:mb-0">
                <img src='/images/logo.svg' alt="Logo" className="h-20 w-auto" />
                <Link to="/" className="hidden sm:block">
                    <span className="hover:text-dark-blue text-lg sm:text-2xl font-bold">{t('museumName')}</span>
                </Link>
            </div>
            <nav className="mb-4 sm:mb-0">
                <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6">
                    <li>
                        {/* Empty list item for alignment purposes */}
                    </li>
                    <li>
                        <Link to="/exhibits" className="hover:text-dark-blue transition duration-300 text-center">{t('exhibitList')}</Link>
                    </li>
                    <li>
                        <Link to="/scan" className="hover:text-dark-blue transition duration-300 text-center">{t('scanQRCode')}</Link>
                    </li>
                </ul>
            </nav>
            <div className="relative">
                <button 
                    onClick={toggleDropdown}
                    className="px-3 py-1 rounded-full border-2 border-dark-blue text-white hover:bg-dark-blue hover:text-white transition duration-300 flex items-center"
                >
                    <img 
                        src={i18n.language === 'en' ? '/images/flags/country-us.svg' : '/images/flags/country-ro.svg'} 
                        alt="Language Flag" 
                        className="h-6 w-6 mr-2" 
                    />
                    {t(i18n.language === 'en' ? 'english' : 'romanian')}
                </button>
                {dropdownOpen && (
                    <div className="absolute right-0 mt-2 w-32 bg-white text-dark-blue rounded shadow-lg border border-dark-blue z-50">
                        <button
                            onClick={() => changeLanguage('en')}
                            className="flex items-center px-4 py-2 hover:bg-dark-blue hover:text-white w-full text-left"
                        >
                            <img src='/images/flags/country-us.svg' alt="English" className="h-5 w-5 mr-2" />
                            {t('english')}
                        </button>
                        <button
                            onClick={() => changeLanguage('ro')}
                            className="flex items-center px-4 py-2 hover:bg-dark-blue hover:text-white w-full text-left"
                        >
                            <img src='/images/flags/country-ro.svg' alt="Romanian" className="h-5 w-5 mr-2" />
                            {t('romanian')}
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
