import { fetchFromAPI } from './Strapi'; // Import the configuration file
import i18n from '../i18n'; // Adjust the import path as necessary


export async function fetchExhibits() {
    try {
        const locale = i18n.language; // Get the current language from i18next
        const endpoint = `exhibits?filters[locale][$eq]=${locale}`;
        const data = await fetchFromAPI(endpoint);

        //console.log('Raw API response:', data); // Log the raw response

        return data.map(exhibit => ({
            ...exhibit,
            imageUrls: exhibit.imageUrls || [], // Use imageUrls field directly
            audioUrls: exhibit.audioUrls || []  // Use audioUrls field directly
        }));
    } catch (error) {
        console.error('Error fetching exhibits:', error);
        throw error;
    }
}

export async function fetchExhibitsByRoom(room, currentUUID) {
    try {
        const locale = i18n.language; // Get the current language from i18next
        const endpoint = `exhibits?filters[room][$eq]=${room}&filters[locale][$eq]=${locale}`;
        const data = await fetchFromAPI(endpoint);

        //console.log('Raw API response:', data); // Log the raw response

        return data
            .filter(exhibit => exhibit.uuid !== currentUUID) // Exclude the current exhibit
            .map(exhibit => ({
                ...exhibit,
                imageUrls: exhibit.imageUrls || [], // Use imageUrls field directly
                audioUrls: exhibit.audioUrls || []  // Use audioUrls field directly
            }));
    } catch (error) {
        console.error('Error fetching exhibits by room:', error);
        throw error;
    }
}

export async function fetchSimilarExhibitsByPriority(room, currentUUID, currentPriority) {
    try {
        const locale = i18n.language; // Get the current language from i18next
        const endpoint = `exhibits?filters[room][$eq]=${room}&filters[locale][$eq]=${locale}`;
        const data = await fetchFromAPI(endpoint);

        // Filter exhibits to exclude the current one and only show exhibits with a higher priority than currentPriority
        const filteredExhibits = data
            .filter(exhibit => exhibit.uuid !== currentUUID && exhibit.priority > currentPriority) // Exclude current exhibit and filter by priority

        // Sort the filtered exhibits by ascending priority
        return filteredExhibits.sort((a, b) => a.priority - b.priority); // Sort by priority in ascending order
    } catch (error) {
        console.error('Error fetching exhibits by room and priority:', error);
        throw error;
    }
}



export async function fetchExhibitByUUID(uuid) {
    try {
        const locale = i18n.language; // Get the current language from i18next
        const endpoint = `exhibits?filters[uuid][$eq]=${uuid}&filters[locale][$eq]=${locale}`;
        const data = await fetchFromAPI(endpoint);

        //console.log('Raw API response:', data); // Log the raw response

        if (data && data.length > 0) {
            const exhibit = data[0];

            // Log the exhibit object for debugging
            //console.log('Exhibit:', exhibit);

            // Construct the response with media URLs if available
            return {
                ...exhibit,
                imageUrls: exhibit.imageUrls || [], // Use imageUrls field directly
                audioUrls: exhibit.audioUrls || []  // Use audioUrls field directly
            };
        } else {
            throw new Error('No exhibit found with the given UUID');
        }
    } catch (error) {
        console.error('Error fetching exhibit:', error);
        throw error;
    }
}