import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function QRScanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [scanned, setScanned] = useState(false);

  const handleScan = (result) => {
    if (result && !scanned) {
      setScanned(true);
      console.log('Scanned data:', result?.text);
      navigate(`/exhibits/${result.text}`);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError('Error accessing camera. Please make sure you have granted camera permissions.');
  };

  if (location.pathname !== '/scan') {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 bg-teal bg-opacity-10">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-4xl font-bold mb-6 text-center">{t('scanQRCode')}</h1>
        
        <div className="relative aspect-square w-full mb-6">
          <QrReader
            constraints={{
              facingMode: 'environment'
            }}
            onResult={handleScan}
            onError={handleError}
            className="w-full h-full"
            videoStyle={{ objectFit: 'cover' }}
          />
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="flex flex-col gap-3">
          <button
            onClick={() => navigate('/exhibits')}
            className="bg-light-yellow text-dark-blue py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
          >
            {t('goToExhibits')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default QRScanner;