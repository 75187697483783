import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import ro from './locales/ro/translation.json';

// Try to get language from localStorage, or use the browser's language as a fallback
const storedLanguage = localStorage.getItem('language');
const browserLanguage = navigator.language || navigator.userLanguage;
const language = storedLanguage || (browserLanguage.includes('ro') ? 'ro' : 'en'); 

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ro: { translation: ro },
        },
        lng: language, // Use the stored or detected language
        fallbackLng: 'en', // Fallback to English if the detected language is not supported
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
